import * as React from 'react';
import styles from './ThreeDots.module.scss';
import IThreeDotsProps from './IThreeDotsProps';
import IThreeDotsState from './IThreeDotsState';

export default class ThreeDots extends React.Component<IThreeDotsProps, IThreeDotsState> {
    public render = (): React.ReactElement<IThreeDotsProps> => {
        return (<div className={styles.ThreeDots}>
            { this.props.title }
            <span className={styles.ThreeDots1}>.</span>
            <span className={styles.ThreeDots2}>.</span>
            <span className={styles.ThreeDots3}>.</span>
        </div>);
    }
}