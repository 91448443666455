import * as React from 'react';
import styles from './Maintenance.module.scss';
import IMaintenanceProps from './IMaintenanceProps';
import IMaintenanceState from './IMaintenanceState';
import ThreeDots from '../ThreeDots/ThreeDots';

export default class Maintenance extends React.Component<IMaintenanceProps, IMaintenanceState> {
    constructor(props: IMaintenanceProps) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement<IMaintenanceProps> => {
        return (<div className={styles.Maintenance}>
            <ThreeDots title="Maintenance" />
        </div>);
    }
}