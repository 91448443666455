import * as React from 'react';
import styles from './ComingSoon.module.scss';
import IComingSoonProps from './IComingSoonProps';
import IComingSoonState from './IComingSoonState';
import ThreeDots from '../ThreeDots/ThreeDots';

export default class ComingSoon extends React.Component<IComingSoonProps, IComingSoonState> {
    constructor(props: IComingSoonProps) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement<IComingSoonProps> => {
        return (<div className={styles.ComingSoon}>
            <ThreeDots title="Coming soon" />
        </div>);
    }
}